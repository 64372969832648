import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { db } from './firebase';
import { collection, query, where, getDocs, doc, updateDoc, increment, getDoc } from "firebase/firestore";
import { slugify } from './utils';

function LoadingSpinner() {
  return (
    <div className="flex justify-center items-center h-32">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
    </div>
  );
}

function WritingDetails({ writings }) {
  const [writing, setWriting] = useState(null);
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [isReading, setIsReading] = useState(false);
  const { slug } = useParams();

  useEffect(() => {
    const fetchWriting = async () => {
      let foundWriting = writings.find(w => slugify(w.title) === slug);
      
      if (!foundWriting) {
        // If not found in prop, fetch from Firestore
        const q = query(collection(db, "writings"), where("slug", "==", slug));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          foundWriting = { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() };
        } else {
          console.log("No such document!");
          return;
        }
      }

      setWriting(foundWriting);
      
      // Fetch the latest like count from Firestore
      const writingDoc = await getDoc(doc(db, "writings", foundWriting.id));
      const latestLikeCount = writingDoc.data().likes || 0;
      setLikeCount(latestLikeCount);

      // Check if the user has liked this writing before
      const isLiked = localStorage.getItem(`liked_${foundWriting.id}`);
      setLiked(isLiked === 'true');
    };

    fetchWriting();
  }, [slug, writings]);

  const handleLike = async () => {
    if (!writing) return;

    const newLikedState = !liked;
    setLiked(newLikedState);
    localStorage.setItem(`liked_${writing.id}`, newLikedState.toString());

    const writingRef = doc(db, "writings", writing.id);
    await updateDoc(writingRef, {
      likes: increment(newLikedState ? 1 : -1)
    });

    // Fetch the updated like count
    const updatedDoc = await getDoc(writingRef);
    const updatedLikeCount = updatedDoc.data().likes || 0;
    setLikeCount(updatedLikeCount);
  };

  const handleReadAloud = () => {
    if ('speechSynthesis' in window) {
      const synthesis = window.speechSynthesis;
      
      if (isReading) {
        synthesis.cancel();
        setIsReading(false);
      } else {
        const text = Array.isArray(writing.fullText) ? writing.fullText.join(' ') : writing.fullText;
        const utterance = new SpeechSynthesisUtterance(text);
        
        // Set the language to Indonesian
        utterance.lang = 'id-ID';
        
        // Try to find an Indonesian voice
        const voices = synthesis.getVoices();
        const indonesianVoice = voices.find(voice => voice.lang === 'id-ID');
        if (indonesianVoice) {
          utterance.voice = indonesianVoice;
        } else {
          console.warn('No Indonesian voice found. Using default voice.');
        }
        
        utterance.onend = () => setIsReading(false);
        synthesis.speak(utterance);
        setIsReading(true);
      }
    } else {
      alert("Sorry, your browser doesn't support text to speech!");
    }
  };

  if (!writing) {
    return (
      <div className="container max-w-5xl mx-auto px-4 md:px-6 py-12">
        <LoadingSpinner />
      </div>
    );
  }

  const formatDate = (date) => {
    const d = date.toDate();
    return d.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className="container max-w-5xl mx-auto px-4 md:px-6 py-12">
      <Helmet>
        <title>{writing.title} | Syahrial's Journals</title>
      </Helmet>
      <div className="flex justify-between items-center mb-6">
        <Link to="/" className="text-primary hover:text-primary/90 inline-block">&larr; Back to Home</Link>
        <button
          onClick={handleReadAloud}
          className="flex items-center space-x-2 px-4 py-2 rounded-full bg-secondary text-secondary-foreground hover:bg-secondary/90"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z" clipRule="evenodd" />
          </svg>
          <span>{isReading ? 'Stop Reading' : 'Read Aloud'}</span>
        </button>
      </div>
      <h1 className="text-4xl font-bold mb-8">{writing.title}</h1>
      <div className="md:flex md:space-x-8 mb-8">
        <img
          src={writing.coverImage || `https://picsum.photos/seed/${writing.id}/800/400`}
          alt={writing.title}
          className="w-full md:w-1/2 lg:w-1/3 h-auto object-cover rounded-lg shadow-lg mb-4 md:mb-0"
        />
        <div className="prose prose-invert max-w-none md:w-1/2 lg:w-2/3">
          {Array.isArray(writing.fullText) ? (
            writing.fullText.map((paragraph, index) => (
              <p key={index} className="mb-4">{paragraph}</p>
            ))
          ) : (
            <p>{writing.fullText}</p>
          )}
          {writing.createdAt && (
            <p className="text-sm text-gray-400 mt-6 italic">
              {formatDate(writing.createdAt)}
            </p>
          )}
          <div className="mt-6 flex items-center space-x-4">
            <button
              onClick={handleLike}
              className={`flex items-center space-x-2 px-4 py-2 rounded-full ${
                liked ? 'bg-primary text-primary-foreground' : 'bg-secondary text-secondary-foreground'
              }`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
              </svg>
              <span>{liked ? 'Liked' : 'Like'}</span>
            </button>
            <span className="text-gray-400">{likeCount} {likeCount === 1 ? 'like' : 'likes'}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WritingDetails;