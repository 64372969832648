import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from './firebase';
import { collection, addDoc, getDocs, doc, updateDoc, deleteDoc, query, orderBy, limit, startAfter } from "firebase/firestore";
import { signOut } from "firebase/auth";
import { slugify } from './utils';

export function LoadingSpinner() {
  return (
    <div className="flex justify-center items-center h-32">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
    </div>
  );
}

function AdminDashboard() {
  const [writings, setWritings] = useState([]);
  const [messages, setMessages] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [fullText, setFullText] = useState('');
  const [coverImage, setCoverImage] = useState('');
  const [editingId, setEditingId] = useState(null);
  const navigate = useNavigate();
  const [currentWritingsPage, setCurrentWritingsPage] = useState(1);
  const [currentMessagesPage, setCurrentMessagesPage] = useState(1);
  const [lastVisibleWriting, setLastVisibleWriting] = useState(null);
  const [lastVisibleMessage, setLastVisibleMessage] = useState(null);
  const itemsPerPage = 5;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchWritings();
    fetchMessages();
  }, []);

  const fetchWritings = async (loadMore = false) => {
    setLoading(true);
    let writingsQuery;
    if (loadMore && lastVisibleWriting) {
      writingsQuery = query(collection(db, "writings"), orderBy("createdAt", "desc"), startAfter(lastVisibleWriting), limit(itemsPerPage));
    } else {
      writingsQuery = query(collection(db, "writings"), orderBy("createdAt", "desc"), limit(itemsPerPage));
    }
    const writingsSnapshot = await getDocs(writingsQuery);
    const writingsList = writingsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    
    if (loadMore) {
      setWritings(prevWritings => [...prevWritings, ...writingsList]);
    } else {
      setWritings(writingsList);
    }
    
    setLastVisibleWriting(writingsSnapshot.docs[writingsSnapshot.docs.length - 1]);
    setLoading(false);
  };

  const fetchMessages = async (loadMore = false) => {
    setLoading(true);
    let messagesQuery;
    if (loadMore && lastVisibleMessage) {
      messagesQuery = query(collection(db, "messages"), orderBy("createdAt", "desc"), startAfter(lastVisibleMessage), limit(itemsPerPage));
    } else {
      messagesQuery = query(collection(db, "messages"), orderBy("createdAt", "desc"), limit(itemsPerPage));
    }
    const messagesSnapshot = await getDocs(messagesQuery);
    const messagesList = messagesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    
    if (loadMore) {
      setMessages(prevMessages => [...prevMessages, ...messagesList]);
    } else {
      setMessages(messagesList);
    }
    
    setLastVisibleMessage(messagesSnapshot.docs[messagesSnapshot.docs.length - 1]);
    setLoading(false);
  };

  const loadMoreWritings = () => {
    fetchWritings(true);
    setCurrentWritingsPage(prevPage => prevPage + 1);
  };

  const loadMoreMessages = () => {
    fetchMessages(true);
    setCurrentMessagesPage(prevPage => prevPage + 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const writingData = {
      title,
      slug: slugify(title),
      description,
      fullText: fullText.split('\n'),
      coverImage,
      createdAt: new Date()
    };

    try {
      if (editingId) {
        await updateDoc(doc(db, "writings", editingId), writingData);
        alert('Writing updated successfully!');
      } else {
        await addDoc(collection(db, "writings"), writingData);
        alert('Writing added successfully!');
      }
      clearForm();
      fetchWritings();
    } catch (error) {
      console.error("Error adding/updating document: ", error);
      alert(`Error: ${error.message}`);
    }
  };

  const handleEdit = (writing) => {
    setEditingId(writing.id);
    setTitle(writing.title);
    setDescription(writing.description);
    setFullText(Array.isArray(writing.fullText) ? writing.fullText.join('\n') : writing.fullText);
    setCoverImage(writing.coverImage);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this writing?')) {
      try {
        await deleteDoc(doc(db, "writings", id));
        alert('Writing deleted successfully!');
        fetchWritings();
      } catch (error) {
        console.error("Error deleting document: ", error);
        alert(`Error deleting writing: ${error.message}`);
      }
    }
  };

  const clearForm = () => {
    setEditingId(null);
    setTitle('');
    setDescription('');
    setFullText('');
    setCoverImage('');
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <div className="container max-w-4xl mx-auto mt-10 p-6 bg-card rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Admin Dashboard</h2>
        <button
          onClick={handleLogout}
          className="bg-destructive text-destructive-foreground px-4 py-2 rounded-md hover:bg-destructive/90"
        >
          Logout
        </button>
      </div>
      <form onSubmit={handleSubmit} className="space-y-4 mb-8">
        <div>
          <label htmlFor="title" className="block text-sm font-medium text-muted-foreground">
            Title
          </label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="mt-1 block w-full px-3 py-2 bg-background border border-input rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
            required
          />
        </div>
        <div>
          <label htmlFor="description" className="block text-sm font-medium text-muted-foreground">
            Description
          </label>
          <input
            type="text"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="mt-1 block w-full px-3 py-2 bg-background border border-input rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
            required
          />
        </div>
        <div>
          <label htmlFor="fullText" className="block text-sm font-medium text-muted-foreground">
            Full Text
          </label>
          <textarea
            id="fullText"
            value={fullText}
            onChange={(e) => setFullText(e.target.value)}
            rows="10"
            className="mt-1 block w-full px-3 py-2 bg-background border border-input rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
            required
          ></textarea>
        </div>
        <div>
          <label htmlFor="coverImage" className="block text-sm font-medium text-muted-foreground">
            Cover Image URL
          </label>
          <input
            type="url"
            id="coverImage"
            value={coverImage}
            onChange={(e) => setCoverImage(e.target.value)}
            className="mt-1 block w-full px-3 py-2 bg-background border border-input rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-primary text-primary-foreground px-4 py-2 rounded-md hover:bg-primary/90"
        >
          {editingId ? 'Update Writing' : 'Add Writing'}
        </button>
        {editingId && (
          <button
            type="button"
            onClick={clearForm}
            className="w-full bg-secondary text-secondary-foreground px-4 py-2 rounded-md hover:bg-secondary/90 mt-2"
          >
            Cancel Edit
          </button>
        )}
      </form>
      <h3 className="text-xl font-bold mb-4 mt-8">Existing Writings</h3>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="space-y-4 mb-8">
          {writings.map((writing) => (
            <div key={writing.id} className="bg-background p-4 rounded-md shadow">
              <h4 className="text-lg font-semibold">{writing.title}</h4>
              <p className="text-muted-foreground">{writing.description}</p>
              <div className="mt-2 space-x-2">
                <button
                  onClick={() => handleEdit(writing)}
                  className="bg-primary text-primary-foreground px-3 py-1 rounded-md hover:bg-primary/90 text-sm"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(writing.id)}
                  className="bg-destructive text-destructive-foreground px-3 py-1 rounded-md hover:bg-destructive/90 text-sm"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
          {writings.length === currentWritingsPage * itemsPerPage && (
            <button
              onClick={loadMoreWritings}
              className="w-full bg-secondary text-secondary-foreground px-4 py-2 rounded-md hover:bg-secondary/90 mt-4"
            >
              Load More Writings
            </button>
          )}
        </div>
      )}
      <h3 className="text-xl font-bold mb-4">Messages</h3>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="space-y-4">
          {messages.map((message) => (
            <div key={message.id} className="bg-background p-4 rounded-md shadow">
              <h4 className="text-lg font-semibold">{message.name}</h4>
              <p className="text-muted-foreground">{message.email}</p>
              <p className="mt-2">{message.message}</p>
              <p className="text-sm text-muted-foreground mt-2">
                {message.createdAt && message.createdAt.toDate().toLocaleString()}
              </p>
            </div>
          ))}
          {messages.length === currentMessagesPage * itemsPerPage && (
            <button
              onClick={loadMoreMessages}
              className="w-full bg-secondary text-secondary-foreground px-4 py-2 rounded-md hover:bg-secondary/90 mt-4"
            >
              Load More Messages
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default AdminDashboard;