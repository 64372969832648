import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCZ39yH41rJarNheVbObRsQw24pJMQjlT0",
    authDomain: "syahrialns-3a5bd.firebaseapp.com",
    projectId: "syahrialns-3a5bd",
    storageBucket: "syahrialns-3a5bd.appspot.com",
    messagingSenderId: "108189507203",
    appId: "1:108189507203:web:9557f593e04e6a547e45b6",
    measurementId: "G-XNWLCH60LR"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };