import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { db, auth } from './firebase';
import { collection, getDocs, query, orderBy, limit, startAfter, addDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import WritingDetails from './WritingDetails';
import AdminLogin from './AdminLogin';
import AdminDashboard from './AdminDashboard';
import { slugify } from './utils';
import { LoadingSpinner } from './AdminDashboard'; // Import the LoadingSpinner component
import { Helmet } from 'react-helmet';
import logo from './logo-syahrialns.png'; // Import the logo

function ProtectedRoute({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return user ? children : <Navigate to="/admin/login" />;
}

function App() {
  const [currentPage, setCurrentPage] = useState(1);
  const [writings, setWritings] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const writingsPerPage = 6;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitStatus, setSubmitStatus] = useState(null);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    fetchWritings();
  }, []);

  const fetchWritings = async () => {
    setLoading(true);
    try {
      const writingsQuery = query(
        collection(db, "writings"),
        orderBy("createdAt", "desc")
      );

      const writingsSnapshot = await getDocs(writingsQuery);
      const writingsList = writingsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setWritings(writingsList);
      setTotalPages(Math.ceil(writingsList.length / writingsPerPage));
    } catch (error) {
      console.error("Error fetching writings: ", error);
    }
    setLoading(false);
  };

  const handlePageChange = async (newPage) => {
    if (newPage > currentPage) {
      await fetchWritings(true);
    } else if (newPage < currentPage) {
      // For simplicity, we'll reload from the beginning when going back
      setLastVisible(null);
      await fetchWritings();
    }
    setCurrentPage(newPage);
  };

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus('submitting');

    try {
      await addDoc(collection(db, "messages"), {
        name,
        email,
        message,
        createdAt: new Date()
      });
      setSubmitStatus('success');
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      console.error("Error submitting message: ", error);
      setSubmitStatus('error');
    }

    // Reset status after 5 seconds
    setTimeout(() => setSubmitStatus(null), 5000);
  };

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Helmet>
          <title>Syahrial's Journals</title>
          <link rel="icon" type="image/png" href="/logo-syahrialns.png" />
        </Helmet>
        <header className="bg-background py-4 px-4 md:px-6 border-b">
          <div className="container max-w-5xl mx-auto flex items-center justify-between">
            <Link to="/" className="flex items-center space-x-2">
              <div className="bg-white rounded-full p-1 flex items-center justify-center">
                <img src={logo} alt="Syahrial's Journals Logo" className="h-8 w-8 object-contain" />
              </div>
              <span className="text-xl font-bold">Syahrial's Journals</span>
            </Link>
            <nav className="hidden md:flex items-center space-x-6">
              <a href="/#about" className="text-muted-foreground hover:text-foreground">About</a>
              <a href="/#writing" className="text-muted-foreground hover:text-foreground">Entries</a>
              <a href="/#contact" className="text-muted-foreground hover:text-foreground">Contact</a>
            </nav>
            <button 
              className="md:hidden"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
          {mobileMenuOpen && (
            <nav className="md:hidden mt-4 space-y-2">
              <a href="/#about" className="block text-muted-foreground hover:text-foreground py-2">About</a>
              <a href="/#writing" className="block text-muted-foreground hover:text-foreground py-2">Entries</a>
              <a href="/#contact" className="block text-muted-foreground hover:text-foreground py-2">Contact</a>
            </nav>
          )}
        </header>
        <Routes>
          <Route path="/" element={
            <>
              <Helmet>
                <title>Home | Syahrial's Journals</title>
              </Helmet>
              <main className="flex-1">
                <section id="hero" className="bg-background py-12 md:py-24 lg:py-32">
                  <div className="container max-w-5xl mx-auto px-4 md:px-6">
                    <div className="grid md:grid-cols-2 gap-8 items-center">
                      <div className="space-y-4">
                        <h1 className="text-4xl font-bold tracking-tight sm:text-5xl md:text-6xl leading-tight">
                          Catatan tentang buku dan kehidupan
                        </h1>
                        <p className="text-muted-foreground text-lg md:text-xl leading-relaxed">
                          Berbagai coretan opini dan pengalaman pribadi yang ingin diabadikan.
                        </p>
                      </div>
                      <img
                        src="https://i.ibb.co.com/c1TGkDq/IMG-20230611-WA0002.jpg"
                        alt="Syahrial Ilyas Nursidiq at POST Bookshop"
                        className="rounded-lg shadow-lg w-full h-auto"
                      />
                    </div>
                  </div>
                </section>
                <section id="about" className="bg-muted py-12 md:py-24 lg:py-32">
                  <div className="container max-w-5xl mx-auto px-4 md:px-6">
                    <div className="grid md:grid-cols-2 gap-8 items-center">
                      <div className="order-2 md:order-1">
                        <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">Hai, Aku Syahrial! :)</h2>
                        <p className="text-muted-foreground text-lg md:text-xl mt-4">
                          Sebagai <i>avid-reader</i>, terkadang bingung di mana tempat untuk menulis refleksi terhadap buku yang sudah kubaca. 
                          Memang ada banyak situs seperti Goodreads, tetapi di situs tersebut banyak distraksi yang ga bikin aku
                          ingin nulis. <i>So, this is the place to dump all my thoughts on books and also, life.</i>
                        </p>
                      </div>
                      <div className="order-1 md:order-2 flex justify-center">
                        <img
                          src="https://i.ibb.co.com/RCm143x/Desain-tanpa-judul-5.jpg"
                          alt="Profil Syahrial Ilyas Nursidiq"
                          className="rounded-full shadow-lg w-64 h-64 object-cover"
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section id="writing" className="bg-background py-12 md:py-24 lg:py-32">
                  <div className="container max-w-5xl mx-auto px-4 md:px-6">
                    <h2 className="text-3xl font-bold tracking-tight sm:text-4xl mb-8">Journal Entries</h2>
                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                          {writings.slice((currentPage - 1) * writingsPerPage, currentPage * writingsPerPage).map((item) => (
                            <div key={item.id} className="bg-card rounded-lg shadow-lg overflow-hidden">
                              <img
                                src={item.coverImage || `https://picsum.photos/seed/${item.id}/300/200`}
                                alt="Writing Cover"
                                className="w-full h-48 object-cover"
                              />
                              <div className="p-6">
                                <Link 
                                  to={`/writing/${slugify(item.title)}`}
                                  className="text-xl font-bold mb-2 cursor-pointer hover:text-primary"
                                >
                                  {item.title}
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="flex justify-center mt-8 space-x-4">
                          <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="w-10 h-10 flex items-center justify-center bg-primary text-primary-foreground rounded-full disabled:opacity-50 hover:bg-primary/90 transition-colors"
                            aria-label="Previous page"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                              <polyline points="15 18 9 12 15 6"></polyline>
                            </svg>
                          </button>
                          <span className="flex items-center px-4 py-2 bg-background border border-input rounded-md">
                            Page {currentPage} of {totalPages}
                          </span>
                          <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className="w-10 h-10 flex items-center justify-center bg-primary text-primary-foreground rounded-full disabled:opacity-50 hover:bg-primary/90 transition-colors"
                            aria-label="Next page"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                              <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </section>
                <section id="contact" className="bg-muted py-12 md:py-24 lg:py-32">
                  <div className="container max-w-5xl mx-auto px-4 md:px-6">
                    <h2 className="text-3xl font-bold tracking-tight sm:text-4xl mb-8">Get in Touch</h2>
                    <div className="grid md:grid-cols-2 gap-8">
                      <div>
                        <p className="text-muted-foreground text-lg md:text-xl mb-4">
                          I'd love to hear from you! Whether you have a book recommendation or just want to
                          chat about literature, feel free to reach out.
                        </p>
                        <form onSubmit={handleContactSubmit} className="space-y-4">
                          <input 
                            type="text" 
                            placeholder="Name" 
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="w-full px-3 py-2 border border-input rounded-md text-black placeholder-gray-500" 
                            required
                          />
                          <input 
                            type="email" 
                            placeholder="Email" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full px-3 py-2 border border-input rounded-md text-black placeholder-gray-500" 
                            required
                          />
                          <textarea 
                            placeholder="Message" 
                            rows="4" 
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            className="w-full px-3 py-2 border border-input rounded-md text-black placeholder-gray-500"
                            required
                          ></textarea>
                          <button 
                            type="submit" 
                            className="bg-primary text-primary-foreground px-4 py-2 rounded-md hover:bg-primary/90"
                            disabled={submitStatus === 'submitting'}
                          >
                            {submitStatus === 'submitting' ? 'Submitting...' : 'Submit'}
                          </button>
                          {submitStatus === 'success' && (
                            <p className="text-green-500">Message sent successfully!</p>
                          )}
                          {submitStatus === 'error' && (
                            <p className="text-red-500">Error sending message. Please try again.</p>
                          )}
                        </form>
                      </div>
                      <div className="flex flex-col items-start space-y-4">
                        <a href="mailto:syahrialns@outlook.com" className="text-muted-foreground hover:text-foreground flex items-center space-x-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect width="20" height="16" x="2" y="4" rx="2"/><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"/></svg>
                          <span>syahrialns@outlook.com</span>
                        </a>
                        <a href="https://www.instagram.com/syahrial.ns" className="text-muted-foreground hover:text-foreground flex items-center space-x-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"/>
                            <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"/>
                            <line x1="17.5" x2="17.51" y1="6.5" y2="6.5"/>
                          </svg>
                          <span>@syahrial.ns</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
              </main>
            </>
          } />
          <Route path="/writing/:slug" element={
            <>
              <Helmet>
                <title>Writing | Syahrial's Journals</title>
              </Helmet>
              <WritingDetails writings={writings} />
            </>
          } />
          <Route path="/admin/login" element={
            <>
              <Helmet>
                <title>Admin Login | Syahrial's Journals</title>
              </Helmet>
              <AdminLogin />
            </>
          } />
          <Route 
            path="/admin/dashboard" 
            element={
              <ProtectedRoute>
                <>
                  <Helmet>
                    <title>Admin Dashboard | Syahrial's Journals</title>
                  </Helmet>
                  <AdminDashboard />
                </>
              </ProtectedRoute>
            } 
          />
        </Routes>
        <footer className="bg-background py-6 px-4 md:px-6 border-t">
          <div className="container max-w-5xl mx-auto flex items-center justify-between">
            <p className="text-muted-foreground text-sm">&copy; 2024 Syahrial Ilyas Nursidiq. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
